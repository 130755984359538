import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import {
  IconButton,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { Col, Row } from '../../../../../styles/grid';
import { ActionButton, Text, WhiteBox } from '../../styles';

import px2vw from '../../../../../utils/responsiveness/px2vw';
import AccessValidate from '../../../../../utils/validations/AccessValidate';
import { LoadingBox } from '../../../../../styles/common';
import { preto } from '../../../../../styles/colors';
import RemovePixelModal from './RemovePixelModal';

import { clearUpdatePixelsListSuccess } from '../../../../../store/modules/clients/actions';
import AddPixelModal from './AddPixelModal';

export default function TabPixelOwnDomain() {
  const { access } = useSelector((state) => state.user);
  const operationalEditAccess = AccessValidate(
    access,
    'admin_operator_edit',
    'operational_edit',
    null,
    false
  );
  const {
    pixelOwnDomainDataLoading,
    updatePixelsListSuccess,
  } = useSelector((state) => state.clients);
  const pixelOwnDomainData = useSelector((state) => state.clients.pixelOwnDomainData);
  const kuppiPixelID = '1609621289422319';
  const [removePixel, setRemovePixel] = useState(null);
  const [removePixelModal, setRemovePixelModal] = useState(false);
  const [addPixelModal, setAddPixelModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (updatePixelsListSuccess) {
      setRemovePixelModal(false);
      setAddPixelModal(false);
      dispatch(clearUpdatePixelsListSuccess());
    }
  }, [updatePixelsListSuccess])

  return (
    <>
      <Col>
        <Row justifyStart alignCenter>
          <Text fontweight="bold" fontsize={px2vw(13)}>
            {`Pixel ID: ${kuppiPixelID} é da Kuppi e aparece em todos os cardápios`}
          </Text>
        </Row>

        <Row justifyStart alignStart margtop={px2vw(20)}>
          {/* Pixel */}
          <WhiteBox margright={px2vw(10)}>
            <Row justifyStart margbottom={px2vw(10)}>
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Pixel
              </Text>
            </Row>
            <Col>
              {!pixelOwnDomainDataLoading && pixelOwnDomainData?.facebook_pixels && pixelOwnDomainData?.facebook_pixels?.map((pixelId, idx) => {
                return (
                  <Row justifyStart>
                    <Text key={`pixelId-${idx}`} fontsize={px2vw(13)} margright={px2vw(10)} margtop={px2vw(9)}>
                      {`• ${pixelId}`}
                    </Text>
                    {operationalEditAccess && (
                      <IconButton size="medium" onClick={() => {
                        setRemovePixel({
                          idx: idx,
                          pixel: pixelId
                        });
                        setRemovePixelModal(true);
                      }}>
                        <DeleteForeverIcon />
                      </IconButton>
                    )}
                  </Row>
                )
              })}
              {!pixelOwnDomainDataLoading && !pixelOwnDomainData?.facebook_pixels && (
                <Text fontsize={px2vw(13)}>
                  Cliente não tem pixel cadastrado
                </Text>
              )}
              {!pixelOwnDomainDataLoading && operationalEditAccess && (
                <Row margtop={px2vw(20)}>
                  <ActionButton onClick={() => setAddPixelModal(true)}>
                    Adicionar Pixel
                  </ActionButton>
                </Row>
              )}
              {pixelOwnDomainDataLoading && (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              )}
            </Col>
          </WhiteBox>
          {/* Own Domain */}
          <WhiteBox margleft={px2vw(10)}>
            <Row justifyStart margbottom={px2vw(10)}>
              <Text fontweight="bold" fontsize={px2vw(13)}>
                Domínio Próprio
              </Text>
            </Row>
            <Col>
              {!pixelOwnDomainDataLoading && pixelOwnDomainData?.domains && pixelOwnDomainData?.domains?.map((domain, idx) => {
                return (
                  <Row key={`domain-${idx}`} justifyStart>
                    <Text fontsize={px2vw(13)} margtop={px2vw(11)}>
                    •
                    </Text>
                    <Text fontsize={px2vw(13)} margtop={px2vw(9)} margleft={px2vw(5)}
                      onClick={() => window.open(`https://${domain}`, '_blank')}
                      slug
                    >
                      {domain}
                    </Text>
                  </Row>
                );
              })}
              {!pixelOwnDomainDataLoading && !pixelOwnDomainData?.domains && (
                <Text fontsize={px2vw(13)}>
                  Cliente não tem domínio próprio cadastrado
                </Text>
              )}
              {pixelOwnDomainDataLoading && (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              )}
            </Col>
          </WhiteBox>
        </Row>
      </Col>
      {removePixelModal && 
        <RemovePixelModal
          removePixel={removePixel}
          onClose={() => setRemovePixelModal(false)}
        />
      }
      {addPixelModal && 
        <AddPixelModal
          onClose={() => setAddPixelModal(false)}
        />
      }
    </>
  );
}
