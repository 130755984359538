import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Col, Row } from '../../styles/grid'

import {
  Header,
  Container,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  EditDetailsButton,
  CheckButton,
  ImageContainer,
  DeleteOrderButton,
} from './styles';
import { IconBox, ArrowIcon } from '../Tables/icons';
import { sort, makePages } from '../Tables/tableOptions';
import TablePerPage from './Structure/TablePerPage';
import {
  getClientDataRequest,
  getClientCategoriesRequest,
} from '../../store/modules/clients/actions';

import px2vw from '../../utils/responsiveness/px2vw';
import { getWebMenuURL } from '../../utils/utils';

function Table({ data, columns, emptyRowsMessage, clientsType, handler }) {
  const [currAdvertiserId, setCurrAdvertiserId] = useState(null);
  const [rows, setRows] = useState([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    if (rows && rows.length) {
      setStructure(makePages(rows.length, rowsCurrentPage));
    }
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows && rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
      setRowsCurrentPage(rows?.length);
    } else {
      setRowsCurrentPage(5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleSort = useCallback(
    (col, isInt) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sort(rows, col, 'asc', isInt));
      } else {
        setSortedCol('');
        setRows(sort(rows, col, 'desc', isInt));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
    setCurrentPage('1');
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  function handleGetClientData(id, clientsType) {
    dispatch(getClientDataRequest(id, clientsType));
    dispatch(getClientCategoriesRequest());
    if (clientsType === 'DataClients') {
      handler.setAdvertiserId(id);
    }
  }

  return (
    <Container>
      <Row>
        <Col width="auto" height="auto" margtop={px2vw(13)}>
          {clientsType === 'orders' && (
            <Row
              width="auto"
              height="auto"
              margtop={px2vw(8)}
              onClick={() => handler.setSelectAllOrders()}
            >
              <CheckButton margright={px2vw(5)}>
                <ImageContainer isChecked={handler.selectAllOrders}>
                  <img
                    src={require('../../assets/images/clients/checkbox@2x.png')}
                    width="100%"
                    height="100%"
                  />
                </ImageContainer>
              </CheckButton>{' '}
              Todos
            </Row>
          )}
        </Col>
        {columns.map((column, idx) => (
          <Header
            flex={column.flex}
            key={`tableColumn${column.field}-${idx}`}
            onClick={() => handleSort(column.field, column.isNumber)}
            width={column.width}
            dataClients={clientsType === 'DataClients' ? true : false}
            district={clientsType === 'District' ? true : false}
          >
            <Row
              width="auto"
              height="auto"
              margleft={
                clientsType === 'District'
                  ? px2vw(-240)
                  : column.title === 'ID' && clientsType === 'DataClients'
                  ? px2vw(-50)
                  : column.title === 'CPF/CNPJ' && clientsType === 'DataClients'
                  ? px2vw(-30)
                  : px2vw(1)
              }
              justifyStart
            >
              <HeaderButton>{column.title}</HeaderButton>
              <IconBox sorted={sortedCol === column.field}>
                <ArrowIcon />
              </IconBox>
            </Row>
          </Header>
        ))}
        <Col width="auto" height="auto" margtop={px2vw(13)}>
          {clientsType === 'orders' && (
            <Row width="auto" height="auto">
              <DeleteOrderButton onClick={() => handler.handleDeleteOrdersModal()} disabled={data.length === 0}>
                <img
                  src={require('../../assets/images/clients/trash@2x.png')}
                  alt="Trash image"
                />
                Excluir
              </DeleteOrderButton>
            </Row>
          )}
        </Col>
      </Row>
      <Row>
        <Hr />
      </Row>
      {structure && rows.length > 0 ? (
        rows.map((item, index) =>
          structure[currentPage].start <= index + 1 &&
          structure[currentPage].end >= index + 1 ? (
            <Fragment key={`table${item.id}-${index}`}>
              <TableRow
                alignCenter
                justifyCenter
                dataClients={clientsType === 'DataClients' ? true : false}
              >
                {clientsType === 'orders' && (
                  <Row
                    width="auto"
                    height="auto"
                    margtop={px2vw(8)}
                    onClick={() => {
                      handler.handleOrdersToDeleteList(item.order_id);
                    }}
                  >
                    <CheckButton>
                      <ImageContainer
                        isChecked={handler.ordersToDelete.includes(
                          item.order_id
                        )}
                      >
                        <img
                          src={require('../../assets/images/clients/checkbox@2x.png')}
                          width="100%"
                          height="100%"
                        />
                      </ImageContainer>
                    </CheckButton>{' '}
                  </Row>
                )}
                {columns.map((column) => (
                  <Content
                    district={clientsType === 'District' ? true : false}
                    maxCharLength={column.maxCharLength}
                    title={(clientsType === 'DataClients' && column.field === 'slug') ? `Clique para abrir o cardápio (${column.render(item)}) em uma nova aba` : !column.hasTooltip ? column.render(item) : undefined}
                    width={column.width}
                    key={column.field}
                    flex={column.flex}
                    slug={clientsType === 'DataClients' && column.field === 'slug'}
                    onClick={() => {
                      if (clientsType === 'DataClients' && column.field === 'slug') {
                        window.open(`https://${column.render(item)}${getWebMenuURL()}`, '_blank')
                      } else {
                        setCurrAdvertiserId(item.advertiser_info_id)
                      }
                    }}
                  >
                    {column.hasTooltip ? (
                      column.render(item, currAdvertiserId)
                    ) : (
                      <span style={column.styles && column.styles(item)}>
                        {column.render ? column.render(item) : '-'}
                      </span>
                    )}
                  </Content>
                ))}
                {['DataClients','MassMessageUnlimitedPackage'].includes(clientsType) && (
                  <EditDetailsButton
                    onClick={() => {
                      handleGetClientData(item.id, clientsType);
                    }}
                  >
                    Editar detalhes
                  </EditDetailsButton>
                )}
              </TableRow>
              <Row>
                <Hr />
              </Row>
            </Fragment>
          ) : null
        )
      ) : (
        <Row padding="15px 0 10px 0">{emptyRowsMessage}</Row>
      )}
      {structure && rows.length > 0 && (
        <Row alignCenter justifyEnd>
          <Row alignCenter justifyEnd height={px2vw(40)} margin="10px">
            <TablePerPage response={handlePerPage} length={rows.length} />
            <Row alignCenter justifyCenter width="10%">
              {structure[currentPage].start} - {structure[currentPage].end} de{' '}
              {rows.length}
            </Row>
            <PagButton
              onClick={() => handleChangePage('prev')}
              disabled={currentPage === firstPage}
              title="Página anterior"
            >
              <PrevIcon disabled={currentPage === firstPage} />
            </PagButton>
            <PagButton
              onClick={() => handleChangePage('next')}
              title="Próxima página"
              disabled={currentPage === lastPage}
            >
              <NextIcon disabled={currentPage === lastPage} />
            </PagButton>
          </Row>
        </Row>
      )}
    </Container>
  );
}

const columnType = PropTypes.shape({
  field: PropTypes.string,
  title: PropTypes.string,
  render: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element,
  ]),
});

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.arrayOf(columnType),
  emptyRowsMessage: PropTypes.string,
};

Table.defaultProps = {
  emptyRowsMessage: 'Nenhuma informação a ser exibida.',
};

export default Table;
