// Total paying clients
export const totalPayingClients = "Total de clientes pagantes:";
// Total possible paying clients
export const totalPossiblePayingClients = "Total possível de clientes pagantes:";
// Due today
export const dueToday = "Vencem hoje:";
// Be Overcome
export const beOvercome = "À vencer:";
// New clients with pending payment
export const newClientsPendingPayment = "Novos clientes pendentes de pgto:";
// Pending renew of paying clients
export const pendingRenewPayingClients = "Renovações de pagantes pendentes:";
// Due date payments
export const totalDueDateNotPaid = "Total de vencidos não pagos:";
export const dueDateNotBlocked = "- Vencidos e não bloqueados:";
export const dueDateBlockedNotCanceled = "- Vencidos já bloqueados e não cancelados:";
export const dueDateCanceled = "- Vencidos já cancelados:";
// Paying with active plans
export const totalPayingActivePlans = "Total de pagantes com plano vigente:";
export const payingStarterPlans = "- Pagantes com plano inaugural:";
export const payingMonthlyPlans = "- Pagantes com plano mensal:";
export const payingTrimestralPlans = "- Pagantes com plano trimestral:";
export const payingQuarterPlans = "- Pagantes com plano quadrimestral:";
export const payingSemestralPlans = "- Pagantes com plano semestral:";
export const payingAnnualPlans = "- Pagantes com plano anual:";
// New sales
export const totalNewSales = "Total de novas vendas:";
export const newSalesStarterPlans = "- Plano inaugural:";
export const newSalesMonthlyPlans = "- Plano mensal:";
export const newSalesTrimestralPlans = "- Plano trimestral:";
export const newSalesQuarterPlans = "- Plano quadrimestral:";
export const newSalesSemestralPlans = "- Plano semestral:";
export const newSalesAnnualPlans = "- Plano anual:";
export const newSalesKuppido10 = "- Kuppidos 1.0 (Externos):";
export const newSalesKuppido20 = "- Kuppidos 2.0 (Internos):";
export const newSalesKuppido30 = "- Kuppidos 3.0 (Revendas):";
export const newSalesKuppido40 = "- Kuppidos 4.0 (Site):";
// Trial
export const totalTrialRegisters = "Total de trials cadastrados:";
export const trialPendingRegisters = "- Total vigentes:";
export const trialNotConvertedRegister = "- Total não convertidos:";
export const trialConvertedRegisters = "- Total convertidos:";
export const trialRegistersKuppido10 = "- Cadastrados Kuppidos 1.0 (Externos):";
export const trialRegistersKuppido20 = "- Cadastrados Kuppidos 2.0 (Internos):";
export const trialRegistersKuppido30 = "- Cadastrados Kuppidos 3.0 (Revendas):";
export const trialRegistersKuppido40 = "- Cadastrados Kuppidos 4.0 (Site):";
export const trialNotConvertedRegistersKuppido10 = "- Não convertidos Kuppidos 1.0 (Externos):";
export const trialNotConvertedRegistersKuppido20 = "- Não convertidos Kuppidos 2.0 (Internos):";
export const trialNotConvertedRegistersKuppido30 = "- Não convertidos Kuppidos 3.0 (Revendas):";
export const trialNotConvertedRegistersKuppido40 = "- Não convertidos Kuppidos 4.0 (Site):";
export const trialConvertedRegistersKuppido10 = "- Convertidos Kuppidos 1.0 (Externos):";
export const trialConvertedRegistersKuppido20 = "- Convertidos Kuppidos 2.0 (Internos):";
export const trialConvertedRegistersKuppido30 = "- Convertidos Kuppidos 3.0 (Revendas):";
export const trialConvertedRegistersKuppido40 = "- Convertidos Kuppidos 4.0 (Site):";
export const trialPendingRegistersKuppido10 = "- Vigentes Kuppidos 1.0 (Externos):";
export const trialPendingRegistersKuppido20 = "- Vigentes Kuppidos 2.0 (Internos):";
export const trialPendingRegistersKuppido30 = "- Vigentes Kuppidos 3.0 (Revendas):";
export const trialPendingRegistersKuppido40 = "- Vigentes Kuppidos 4.0 (Site):";
// Legends
export const totalPayingClientsLbl = `${totalNewSales.replace(':', '')} + ${totalPayingActivePlans.replace(':', '')}`;
export const totalPossiblePayingClientsLbl = `${totalPayingActivePlans.replace(':', '')}` + 
  ` + ${totalNewSales.replace(':', '')}` + 
  ` + ${dueDateNotBlocked.replace(':', '').replace('-', '')}` + 
  ` + ${dueDateBlockedNotCanceled.replace(':', '').replace('-', '')}` + 
  ` + ${dueToday.replace(':', '')}` + 
  ` + ${beOvercome.replace(':', '')}` + 
  ` + ${newClientsPendingPayment.replace(':', '')}` + 
  ` + ${pendingRenewPayingClients.replace(':', '')}`;
export const pendingRenewPayingClientsLbl = "Clientes que ainda vão ter a sua nova assinatura criada";
export const newClientsPendingPaymentLbl = "Clientes que foram cadastrados como pagantes e que ainda não pagaram (Fantasmas)";
export const totalDueDateNotPaidLbl = "Clientes que já estão vencidos e não pagaram";
export const dueTodayLbl = "Clientes que tem assinatura que vencem hoje";
export const beOvercomeLbl = "Clientes que já tiveram a nova assinatura criada e que não chegou a data de vencimento ainda";
export const totalPayingActivePlansLbl = "Clientes pagantes que tem plano vigente";
export const totalNewSalesLbl = "Novas vendas";
export const totalTrialRegistersLbl = "Clientes trial";
