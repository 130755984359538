/** Client Data **/
export function getClientsRequest(criteria) {
  return {
    type: '@clients/GET_CLIENTS_REQUEST',
    payload: { criteria },
  };
}

export function getClientsSuccess(clients) {
  return {
    type: '@clients/GET_CLIENTS_SUCCESS',
    payload: { clients },
  };
}

export function getClientsFailure() {
  return {
    type: '@clients/GET_CLIENTS_FAILURE',
  };
}

export function getClientDataRequest(advertiserInfoId, screen = null) {
  return {
    type: '@clients/GET_CLIENT_DATA_REQUEST',
    payload: { advertiserInfoId, screen },
  };
}

export function getClientDataSuccess(clientData) {
  return {
    type: '@clients/GET_CLIENT_DATA_SUCCESS',
    payload: { clientData },
  };
}

export function getClientDataFailure() {
  return {
    type: '@clients/GET_CLIENT_DATA_FAILURE',
  };
}

export function getClientCategoriesRequest() {
  return {
    type: '@clients/GET_CLIENT_CATEGORIES_REQUEST',
  };
}

export function getClientCategoriesSuccess(categories) {
  return {
    type: '@clients/GET_CLIENT_CATEGORIES_SUCCESS',
    payload: { categories },
  };
}

export function getClientCategoriesFailure() {
  return {
    type: '@clients/GET_CLIENT_CATEGORIES_FAILURE',
  };
}

export function updateRepresentativeDataRequest(
  advertiserInfoId,
  email,
  representativeName,
  phoneWhatsappRepresentative
) {
  return {
    type: '@clients/UPDATE_REPRESENTATIVE_DATA_REQUEST',
    payload: { advertiserInfoId, email, representativeName, phoneWhatsappRepresentative },
  };
}

export function updateRepresentativeDataSuccess() {
  return {
    type: '@clients/UPDATE_REPRESENTATIVE_DATA_SUCCESS',
  };
}

export function updateRepresentativeDataFailure() {
  return {
    type: '@clients/UPDATE_REPRESENTATIVE_DATA_FAILURE',
  };
}

export function generateNewPasswordRequest(advertiserInfoId) {
  return {
    type: '@clients/GENERATE_NEW_PASSWORD_REQUEST',
    payload: {
      advertiserInfoId,
    },
  };
}

export function generateNewPasswordSuccess() {
  return {
    type: '@clients/GENERATE_NEW_PASSWORD_SUCCESS',
  };
}

export function generateNewPasswordFailure() {
  return {
    type: '@clients/GENERATE_NEW_PASSWORD_FAILURE',
  };
}

export function updateStoreDataRequest(
  advertiserInfoId,
  name,
  cpfCnpj,
  category,
  latitude,
  longitude,
  isVisibleOnWebMenu
) {
  return {
    type: '@clients/UPDATE_STORE_DATA_REQUEST',
    payload: { advertiserInfoId, name, cpfCnpj, category, latitude, longitude, isVisibleOnWebMenu },
  };
}

export function updateStoreDataSuccess() {
  return {
    type: '@clients/UPDATE_STORE_DATA_SUCCESS',
  };
}

export function updateStoreDataFailure() {
  return {
    type: '@clients/UPDATE_STORE_DATA_FAILURE',
  };
}

export function updateStoreStatusDataRequest(advertiserInfoId, noOverdueBlockUntilAt) {
  return {
    type: '@clients/UPDATE_STORE_STATUS_DATA_REQUEST',
    payload: { advertiserInfoId, noOverdueBlockUntilAt },
  };
}

export function updateStoreStatusDataSuccess() {
  return {
    type: '@clients/UPDATE_STORE_STATUS_DATA_SUCCESS',
  };
}

export function updateStoreStatusDataFailure() {
  return {
    type: '@clients/UPDATE_STORE_STATUS_DATA_FAILURE',
  };
}

/** Near Clients **/
export function getNearClientsRequest(
  state,
  city,
  latitude,
  longitude,
  radius
) {
  return {
    type: '@clients/GET_NEAR_CLIENTS_REQUEST',
    payload: {
      state,
      city,
      latitude,
      longitude,
      radius,
    },
  };
}

export function getNearClientsSuccess(nearClients) {
  return {
    type: '@clients/GET_NEAR_CLIENTS_SUCCESS',
    payload: { nearClients },
  };
}

export function getNearClientsFailure() {
  return {
    type: '@clients/GET_NEAR_CLIENTS_FAILURE',
  };
}

/** Subscriptions **/
export function getSubscriptionsListRequest() {
  return {
    type: '@clients/GET_SUBSCRIPTIONS_LIST_REQUEST',
  };
}

export function formatSubscriptions(subscriptions) {
  return {
    type: '@clients/FORMAT_SUBSCRIPTIONS',
    payload: { subscriptions },
  };
}

export function getSubscriptionsListSuccess(subscriptionsList) {
  return {
    type: '@clients/GET_SUBSCRIPTIONS_LIST_SUCCESS',
    payload: { subscriptionsList },
  };
}

export function getSubscriptionsListFailure() {
  return {
    type: '@clients/GET_SUBSCRIPTIONS_LIST_FAILURE',
  };
}

export function getSubscriptionsRequest(advertiserInfoId) {
  return {
    type: '@clients/GET_SUBSCRIPTIONS_REQUEST',
    payload: { advertiserInfoId },
  };
}

export function getSubscriptionsSuccess(
  subscriptions,
  isDuePayment,
  hasFreeTrialValid
) {
  return {
    type: '@clients/GET_SUBSCRIPTIONS_SUCCESS',
    payload: { subscriptions, isDuePayment, hasFreeTrialValid },
  };
}

export function getSubscriptionsFailure() {
  return {
    type: '@clients/GET_SUBSCRIPTIONS_FAILURE',
  };
}

export function getRecurringSuccess(recurring) {
  return {
    type: '@clients/GET_RECURRING_SUCCESS',
    payload: { recurring },
  };
}

export function updateStoreSubscriptionDataRequest(
  advertiserInfoId,
  subscriptionId,
  amount,
  signatureUpdatePeriod
) {
  return {
    type: '@clients/UPDATE_STORE_SUBSCRIPTION_DATA_REQUEST',
    payload: {
      advertiserInfoId,
      subscriptionId,
      amount,
      signatureUpdatePeriod,
    },
  };
}

export function updateStoreSubscriptionDataSuccess() {
  return {
    type: '@clients/UPDATE_STORE_SUBSCRIPTION_DATA_SUCCESS',
  };
}

export function updateStoreSubscriptionDataFailure() {
  return {
    type: '@clients/UPDATE_STORE_SUBSCRIPTION_DATA_FAILURE',
  };
}

export function manualConciliationRequest(
  advertiserInfoId,
  subscriptionId,
  transactionId,
  paymentType
) {
  return {
    type: '@clients/MANUAL_CONCILIATION_REQUEST',
    payload: { advertiserInfoId, subscriptionId, transactionId, paymentType },
  };
}

export function manualConciliationSuccess() {
  return {
    type: '@clients/MANUAL_CONCILIATION_SUCCESS',
  };
}

export function manualConciliationFailure() {
  return {
    type: '@clients/MANUAL_CONCILIATION_FAILURE',
  };
}

export function cancelSubscriptionRequest(advertiserInfoId) {
  return {
    type: '@clients/CANCEL_SUBSCRIPTION_REQUEST',
    payload: {
      advertiserInfoId,
    },
  };
}

export function cancelSubscriptionSuccess() {
  return {
    type: '@clients/CANCEL_SUBSCRIPTION_SUCCESS',
  };
}

export function cancelSubscriptionFailure() {
  return {
    type: '@clients/CANCEL_SUBSCRIPTION_FAILURE',
  };
}

export function removeCreditCardRequest(advertiserInfoId) {
  return {
    type: '@clients/REMOVE_CREDIT_CARD_REQUEST',
    payload: {
      advertiserInfoId,
    },
  };
}

export function removeCreditCardSuccess() {
  return {
    type: '@clients/REMOVE_CREDIT_CARD_SUCCESS',
  };
}

export function removeCreditCardFailure() {
  return {
    type: '@clients/REMOVE_CREDIT_CARD_FAILURE',
  };
}

/** Kuppi Boost **/
export function getKuppiBoostDataRequest(advertiserInfoId) {
  return {
    type: '@clients/GET_KUPPI_BOOST_DATA_REQUEST',
    payload: { advertiserInfoId },
  };
}

export function getKuppiBoostDataSuccess(kuppiBoostData) {
  return {
    type: '@clients/GET_KUPPI_BOOST_DATA_SUCCESS',
    payload: { kuppiBoostData },
  };
}

export function getKuppiBoostDataFailure() {
  return {
    type: '@clients/GET_KUPPI_BOOST_DATA_FAILURE',
  };
}

export function getBoostCampaignsRequest(advertiserInfoId) {
  return {
    type: '@clients/GET_BOOST_CAMPAIGNS_REQUEST',
    payload: { advertiserInfoId },
  };
}

export function getBoostCampaignsSuccess(campaignsData) {
  return {
    type: '@clients/GET_BOOST_CAMPAIGNS_SUCCESS',
    payload: { campaignsData },
  };
}

export function getBoostCampaignsFailure() {
  return {
    type: '@clients/GET_BOOST_CAMPAIGNS_FAILURE',
  };
}

export function addKuppiBoostBalanceRequest(advertiserInfoId, balance, mgmId) {
  return {
    type: '@clients/ADD_KUPPI_BOOST_BALANCE_REQUEST',
    payload: { advertiserInfoId, balance, mgmId },
  };
}

export function addKuppiBoostBalanceSuccess() {
  return {
    type: '@clients/ADD_KUPPI_BOOST_BALANCE_SUCCESS',
  };
}

export function addKuppiBoostBalanceFailure() {
  return {
    type: '@clients/ADD_KUPPI_BOOST_BALANCE_FAILURE',
  };
}

export function paymentReversalRequest(advertiserInfoId, campaignId) {
  return {
    type: '@clients/PAYMENT_REVERSAL_REQUEST',
    payload: { advertiserInfoId, campaignId },
  };
}

export function paymentReversalSuccess() {
  return {
    type: '@clients/PAYMENT_REVERSAL_SUCCESS',
  };
}

export function paymentReversalFailure() {
  return {
    type: '@clients/PAYMENT_REVERSAL_FAILURE',
  };
}

export function editFacebookInstagramIdRequest(advertiserInfoId, facebookId, instagramId) {
  return {
    type: '@clients/EDIT_FACEBOOK_INSTAGRAM_ID_REQUEST',
    payload: { advertiserInfoId, facebookId, instagramId },
  };
}

export function editFacebookInstagramIdSuccess() {
  return {
    type: '@clients/EDIT_FACEBOOK_INSTAGRAM_ID_SUCCESS',
  };
}

export function editFacebookInstagramIdFailure() {
  return {
    type: '@clients/EDIT_FACEBOOK_INSTAGRAM_ID_FAILURE',
  };
}

/** Neighbordhood **/
export function getLocalitiesRequest(selectedState) {
  return {
    type: '@clients/GET_LOCALITIES_REQUEST',
    payload: { selectedState },
  };
}

export function getLocalitiesSuccess(localities) {
  return {
    type: '@clients/GET_LOCALITIES_SUCCESS',
    payload: { localities },
  };
}

export function getLocalitiesFailure() {
  return {
    type: '@clients/GET_LOCALITIES_FAILURE',
  };
}

export function getDistrictsRequest(localityId) {
  return {
    type: '@clients/GET_DISTRICTS_REQUEST',
    payload: { localityId },
  };
}

export function getDistrictsSuccess(districts) {
  return {
    type: '@clients/GET_DISTRICTS_SUCCESS',
    payload: { districts },
  };
}

export function getDistrictsFailure() {
  return {
    type: '@clients/GET_DISTRICTS_FAILURE',
  };
}

export function addDistrictRequest(dneLocalityId, neighborhood) {
  return {
    type: '@clients/ADD_DISTRICT_REQUEST',
    payload: {
      dneLocalityId,
      neighborhood,
    },
  };
}

export function addDistrictSuccess() {
  return {
    type: '@clients/ADD_DISTRICT_SUCCESS',
  };
}

export function addDistrictFailure() {
  return {
    type: '@clients/ADD_DISTRICT_FAILURE',
  };
}

export function districtClearSuccess() {
  return {
    type: '@clients/DISTRICT_CLEAR_SUCCESS',
  };
}

/** Orders **/
export function getOrdersRequest(ordersIds) {
  return {
    type: '@clients/GET_ORDERS_REQUEST',
    payload: { ordersIds },
  };
}

export function getOrdersSuccess(orders) {
  return {
    type: '@clients/GET_ORDERS_SUCCESS',
    payload: { orders },
  };
}

export function getOrdersFailure() {
  return {
    type: '@clients/GET_ORDERS_FAILURE',
  };
}

export function deleteOrdersRequest(ordersIds) {
  return {
    type: '@clients/DELETE_ORDERS_REQUEST',
    payload: { ordersIds },
  }
}

export function deleteOrdersSuccess() {
  return {
    type: '@clients/DELETE_ORDERS_SUCCESS',
  };
}

export function deleteOrdersFailure() {
  return {
    type: '@clients/DELETE_ORDERS_FAILURE',
  };
}

/** Pixel / OwnDomain **/
export function getPixelOwnDomainDataRequest(advertiserInfoId) {
  return {
    type: '@clients/GET_PIXEL_OWN_DOMAIN_DATA_REQUEST',
    payload: { advertiserInfoId },
  };
}

export function getPixelOwnDomainDataSuccess(pixelOwnDomainData) {
  return {
    type: '@clients/GET_PIXEL_OWN_DOMAIN_DATA_SUCCESS',
    payload: { pixelOwnDomainData },
  };
}

export function getPixelOwnDomainDataFailure() {
  return {
    type: '@clients/GET_PIXEL_OWN_DOMAIN_DATA_FAILURE',
  };
}

export function updatePixelsListRequest(advertiserInfoId, pixelsList) {
  return {
    type: '@clients/UPDATE_PIXELS_LIST_REQUEST',
    payload: { advertiserInfoId, pixelsList },
  };
}

export function updatePixelsListSuccess(pixelOwnDomainData) {
  return {
    type: '@clients/UPDATE_PIXELS_LIST_SUCCESS',
    payload: { pixelOwnDomainData },
  };
}

export function updatePixelsListFailure() {
  return {
    type: '@clients/UPDATE_PIXELS_LIST_FAILURE',
  };
}

export function clearUpdatePixelsListSuccess() {
  return {
    type: '@clients/CLEAR_UPDATE_PIXELS_LIST_SUCCESS',
  };
}

/** Whatsapp **/
export function getWhatsappDataRequest(advertiserInfoId) {
  return {
    type: '@clients/GET_WHATSAPP_DATA_REQUEST',
    payload: { advertiserInfoId },
  };
}

export function getWhatsappDataSuccess(whatsappData) {
  return {
    type: '@clients/GET_WHATSAPP_DATA_SUCCESS',
    payload: { whatsappData },
  };
}

export function getWhatsappDataFailure() {
  return {
    type: '@clients/GET_WHATSAPP_DATA_FAILURE',
  };
}

export function forceWhatsappDisconnectionRequest(advertiserInfoId) {
  return {
    type: '@clients/FORCE_WHATSAPP_DISCONNECTION_REQUEST',
    payload: { advertiserInfoId },
  };
}

export function forceWhatsappDisconnectionSuccess(whatsappData) {
  return {
    type: '@clients/FORCE_WHATSAPP_DISCONNECTION_SUCCESS',
    payload: { whatsappData },
  };
}

export function forceWhatsappDisconnectionFailure() {
  return {
    type: '@clients/FORCE_WHATSAPP_DISCONNECTION_FAILURE',
  };
}

/** Mass Message **/
export function getMassMessageClientDataRequest(advertiserInfoId) {
  return {
    type: '@clients/GET_MASS_MESSAGE_CLIENT_DATA_REQUEST',
    payload: { advertiserInfoId },
  };
}

export function getMassMessageClientDataSuccess(massMessageData) {
  return {
    type: '@clients/GET_MASS_MESSAGE_CLIENT_DATA_SUCCESS',
    payload: { massMessageData },
  };
}

export function getMassMessageClientDataFailure() {
  return {
    type: '@clients/GET_MASS_MESSAGE_CLIENT_DATA_FAILURE',
  };
}

export function getMassMessageClientCampaignsRequest(advertiserInfoId) {
  return {
    type: '@clients/GET_MASS_MESSAGE_CLIENT_CAMPAIGNS_REQUEST',
    payload: { advertiserInfoId },
  };
}

export function getMassMessageClientCampaignsSuccess(campaignsData) {
  return {
    type: '@clients/GET_MASS_MESSAGE_CLIENT_CAMPAIGNS_SUCCESS',
    payload: { campaignsData },
  };
}

export function getMassMessageClientCampaignsFailure() {
  return {
    type: '@clients/GET_MASS_MESSAGE_CLIENT_CAMPAIGNS_FAILURE',
  };
}

export function addMassMessageClientBalanceRequest(advertiserInfoId, balance, balanceType) {
  return {
    type: '@clients/ADD_MASS_MESSAGE_CLIENT_BALANCE_REQUEST',
    payload: { advertiserInfoId, balance, balanceType },
  };
}

export function addMassMessageClientBalanceSuccess() {
  return {
    type: '@clients/ADD_MASS_MESSAGE_CLIENT_BALANCE_SUCCESS',
  };
}

export function addMassMessageClientBalanceFailure() {
  return {
    type: '@clients/ADD_MASS_MESSAGE_CLIENT_BALANCE_FAILURE',
  };
}

export function editMassMessageClientRequest(advertiserInfoId, isUsingPackage, newAmount) {
  return {
    type: '@clients/EDIT_MASS_MESSAGE_CLIENT_REQUEST',
    payload: { advertiserInfoId, isUsingPackage, newAmount },
  };
}

export function editMassMessageClientSuccess() {
  return {
    type: '@clients/EDIT_MASS_MESSAGE_CLIENT_SUCCESS',
  };
}

export function editMassMessageClientFailure() {
  return {
    type: '@clients/EDIT_MASS_MESSAGE_CLIENT_FAILURE',
  };
}

/** Common **/
export function clearSuccess() {
  return {
    type: '@clients/CLEAR_SUCCESS',
  };
}

export function clearClientDataSuccess() {
  return {
    type: '@clients/CLEAR_CLIENT_DATA_SUCCESS',
  };
}

export function clearforceWhatsappDisconnectionSuccess() {
  return {
    type: '@clients/CLEAR_FORCE_WHATSAPP_DISCONNECTION_SUCCESS',
  };
}

export function clearMassMessageClientSuccess() {
  return {
    type: '@clients/CLEAR_MASS_MESSAGE_CLIENT_SUCCESS',
  };
}

export function clearClientRequestErrors() {
  return {
    type: '@clients/CLEAR_CLIENT_REQUEST_ERRORS'
  };
}
