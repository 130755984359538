import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { Row } from '../../../styles/grid';
import px2vw from '../../../utils/responsiveness/px2vw';
import { Title } from '../styles';
import { SearchButton, SearchInput, WhiteBox } from './styles';
import DataClientsTable from '../../../components/Tables/DataClientsTable';
import AdvertiserDetails from './AdvertiserDetails';
import { ContentPage } from '../../_layouts/styles/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearClientDataSuccess,
  clearSuccess,
  getClientsRequest,
} from '../../../store/modules/clients/actions';
import { LoadingBox } from '../../../styles/common';
import { preto } from '../../../styles/colors';


export default function DataClients() {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients.clients);

  const {
    clientsLoading,
    clientDataLoading,
    clientDataSuccess,
    clientDataError,
  } = useSelector((state) => state.clients);

  const [advertiserDetails, setAdvertiserDetails] = useState(false);
  const [advertiserId, setAdvertiserId] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');

  const handler = {
    advertiserId: advertiserId,
    setAdvertiserDetails: () => setAdvertiserDetails(),
    setAdvertiserId: (id) => setAdvertiserId(id),
    setSearchInputValue: () => setSearchInputValue()
  };

  function handleGetClients(criteria) {
    dispatch(getClientsRequest(criteria));
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter' && searchInputValue !== '') {
      event.preventDefault();
      handleGetClients(searchInputValue)
    }
  };

  const Content = (
    <ContentPage>
      <Row height="auto" alignStart justifyStart>
        <Title>Cadastro</Title>
      </Row>
      <WhiteBox justifyStart height="auto" margtop={px2vw(30)}>
        <Row height="auto" spaceBetween alignCenter>
          <Title boxTitle width={px2vw(300)}>
            Buscar
          </Title>

          <Row width="auto">
            <SearchInput
              id="searchClient"
              name="searchClient"
              type="text"
              placeholder="Digite o Nome, ID ou CPF/ CNPJ do cliente"
              value={searchInputValue}
              onChange={(e) => setSearchInputValue(e.target.value)}
              height={px2vw(30)}
              width={px2vw(350)}
              padleft={px2vw(10)}
              onKeyPress={handleKeyPress} 
            />
            <SearchButton
              onClick={() => handleGetClients(searchInputValue)}
              disabled={searchInputValue === '' ? true : false}
            >
              {clientsLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Pesquisar'
              )}
            </SearchButton>
          </Row>
        </Row>

        <Row width="auto">
          <DataClientsTable data={clients} handler={handler} />
        </Row>
      </WhiteBox>
    </ContentPage>
  );

  useEffect(() => {
    dispatch(clearSuccess())
  },[]);

  useEffect(() => {
    if (clientDataSuccess) {
      setAdvertiserDetails(true);
      dispatch(clearClientDataSuccess());
    }
  }, [clientDataSuccess]);

  useEffect(() => {
    if (clientDataError) {
      setAdvertiserDetails(false);
    }
  }, [clientDataError]);

  if (clientDataLoading || advertiserDetails) {
    return (
      <>
        {clientDataLoading ? (
          <LoadingBox dataClientsLoading>
            <Spinner
              style={{
                width: '1.2rem',
                height: '1.2rem',
                color: preto,
              }}
            />
          </LoadingBox>
        ) : (
          <AdvertiserDetails handler={handler} />
        )}
      </>
    );
  } else {
    return Content;
  }
}
