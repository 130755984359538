import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../styles/common';
import { preto } from '../../styles/colors';
import { Col, Row } from '../../styles/grid';
import { CloseButton, ModalTitleText } from '../Modal/styles';
import {
  CancelButton,
  ConfirmButton,
  InputData,
  InputTitle,
} from '../EditResponsibleDataModal/styles';

import closeButtonImg from '../../assets/images/close_button/close_button.png';

import px2vw from '../../utils/responsiveness/px2vw';

import Modal from '../Modal';

import { cancelSubscriptionRequest } from '../../store/modules/clients/actions';

export default function CancelSubscriptionModal({ handler }) {
  const { cancelSubscriptionLoading } = useSelector(
    (state) => state.clients
  );
  const [cancelTextConfirmation, setCancelTextConfirmation] = useState('');
  const dispatch = useDispatch();

  function handleCloseModal() {
    handler.setCancelSubscription(false);
  }

  return (
    <Modal
      isVisible={handler.cancelSubscription}
      width={px2vw(500)}
      height="auto"
      pad={px2vw(15)}
    >
      <Row justifyEnd>
        <CloseButton 
          onClick={handleCloseModal}
          disabled={cancelSubscriptionLoading}
        >
          <img src={closeButtonImg} alt="close" width="100%" height="100%" />
        </CloseButton>
      </Row>

      <Row>
        <ModalTitleText>Cancelar Assinatura</ModalTitleText>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          Tem certeza que deseja cancelar?
        </InputTitle>
      </Row>

      <Row margtop={px2vw(10)}>
        <InputTitle fontweight="normal">
          Digite 'cancelar assinatura' para efetuar o cancelamento
        </InputTitle>
      </Row>

      <Row>
        <InputData
          name="cancel"
          type="text"
          placeholder="cancelar assinatura"
          width={px2vw(400)}
          value={cancelTextConfirmation}
          onChange={(e) => setCancelTextConfirmation(e.target.value)}
        />
      </Row>

      <Row margtop={px2vw(20)}>
        <Col alignEnd>
          <CancelButton 
            onClick={() => {
              if (cancelTextConfirmation === 'cancelar assinatura') {
                dispatch(cancelSubscriptionRequest(handler.advertiserInfoId));
              }
            }} 
            disabled={cancelTextConfirmation !== 'cancelar assinatura' || cancelSubscriptionLoading}
          >
            {cancelSubscriptionLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Cancelar Assinatura'
              )}
            
          </CancelButton>
        </Col>
        <Col margleft={px2vw(20)}>
          <ConfirmButton 
            onClick={handleCloseModal}
            disabled={cancelSubscriptionLoading}
          >
            Voltar
          </ConfirmButton>
        </Col>
      </Row>
    </Modal>
  );
}
