import React from 'react';
import {Spinner} from 'reactstrap';

import px2vw from '../../../utils/responsiveness/px2vw';
import {Col, Row} from '../../../styles/grid';
import {preto} from '../../../styles/colors';

import {
  Content, 
  LogoKuppi,
  MonthNavigationButton,
  InfoDataBox,
  InfoDataGroupBox,
  InfoNumberText,
  InfoTypeText,
  InfoNumberTotalText,
  InfoTypeTotalText
} from '../styles';

import {
  beOvercome,
  beOvercomeLbl,
  dueDateBlockedNotCanceled,
  dueDateCanceled,
  dueDateNotBlocked,
  dueToday,
  dueTodayLbl,
  newClientsPendingPayment,
  newClientsPendingPaymentLbl,
  newSalesAnnualPlans,
  newSalesKuppido10,
  newSalesKuppido20,
  newSalesKuppido30,
  newSalesKuppido40,
  newSalesMonthlyPlans,
  newSalesQuarterPlans,
  newSalesSemestralPlans,
  newSalesStarterPlans,
  newSalesTrimestralPlans,
  payingAnnualPlans,
  payingMonthlyPlans,
  payingQuarterPlans,
  payingSemestralPlans,
  payingStarterPlans,
  payingTrimestralPlans,
  pendingRenewPayingClients,
  pendingRenewPayingClientsLbl,
  totalDueDateNotPaid,
  totalDueDateNotPaidLbl,
  totalNewSales,
  totalNewSalesLbl,
  totalPayingActivePlans,
  totalPayingActivePlansLbl,
  totalPayingClients,
  totalPayingClientsLbl,
  totalPossiblePayingClients,
  totalPossiblePayingClientsLbl,
  totalTrialRegisters,
  totalTrialRegistersLbl,
  trialConvertedRegisters,
  trialConvertedRegistersKuppido10,
  trialConvertedRegistersKuppido20,
  trialConvertedRegistersKuppido30,
  trialConvertedRegistersKuppido40,
  trialNotConvertedRegister,
  trialNotConvertedRegistersKuppido10,
  trialNotConvertedRegistersKuppido20,
  trialNotConvertedRegistersKuppido30,
  trialNotConvertedRegistersKuppido40,
  trialPendingRegisters,
  trialPendingRegistersKuppido10,
  trialPendingRegistersKuppido20,
  trialPendingRegistersKuppido30,
  trialPendingRegistersKuppido40,
  trialRegistersKuppido10,
  trialRegistersKuppido20,
  trialRegistersKuppido30,
  trialRegistersKuppido40
} from '../constants';

export default function MonthlyPaymentsMetricsWeb({handler}) {
  const metricsContent = (
    <Content>
      <Col height="auto" margbottom={px2vw(40)}>
        {/* Side Bar */}
        <Row justifyCenter alignCenter>
          <Row justifyCenter height={px2vw(60)} width={px2vw(200)}>
            <LogoKuppi
              src={require('../../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
            />
          </Row>
          <Row alignCenter margtop={px2vw(35)} width="auto">
            <MonthNavigationButton isActive={handler.activeMonth === 'previous3'} onClick={() => handler.changeMonthMetric('previous3')}>
              {`${handler.previousMonth3.capitalize()}/${handler.previousYear3}`}
            </MonthNavigationButton>
            <MonthNavigationButton isActive={handler.activeMonth === 'previous2'} onClick={() => handler.changeMonthMetric('previous2')}>
              {`${handler.previousMonth2.capitalize()}/${handler.previousYear2}`}
            </MonthNavigationButton>
            <MonthNavigationButton isActive={handler.activeMonth === 'previous1'} onClick={() => handler.changeMonthMetric('previous1')}>
              {`${handler.previousMonth.capitalize()}/${handler.previousYear}`}
            </MonthNavigationButton>
            <MonthNavigationButton isActive={handler.activeMonth === 'current'} onClick={() => handler.changeMonthMetric('current')}>
              {`${handler.currentMonth.capitalize()}/${handler.currentYear}`}
            </MonthNavigationButton>
          </Row>
        </Row>
        <Row margtop={px2vw(40)}>
          <Col width="auto">
            {/* Total of Paying Advertisers Metrics */}
            <InfoDataGroupBox 
              width={px2vw(410)}
              title={totalPayingClientsLbl}
            >           
              <Row spaceBetween>
                <InfoTypeTotalText>{totalPayingClients}</InfoTypeTotalText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberTotalText>
                    {(handler.monthlyPaymentMetrics.total_metrics && handler.monthlyPaymentMetrics.total_metrics.total_payments) || '-'}
                  </InfoNumberTotalText>
                )}
              </Row>
            </InfoDataGroupBox>
            {/* Possible Number of Paying Advertisers Metrics */}
            <InfoDataGroupBox 
              width={px2vw(410)} 
              margtop={px2vw(20)}
              title={totalPossiblePayingClientsLbl}
            >
              <Row spaceBetween>
                <InfoTypeTotalText>{totalPossiblePayingClients}</InfoTypeTotalText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberTotalText>
                    {(handler.monthlyPaymentMetrics.total_metrics && handler.monthlyPaymentMetrics.total_metrics.total_possible_paying_advertisers) || '-'}
                  </InfoNumberTotalText>
                )}
              </Row>
            </InfoDataGroupBox>
            {/* Pending Renew */}
            {/* Recurrings renews until end of month */}
            <InfoDataGroupBox 
              margtop={px2vw(20)} 
              width={px2vw(410)}
              title={pendingRenewPayingClientsLbl}
            >
              <Row spaceBetween>
                <InfoTypeTotalText>{pendingRenewPayingClients}</InfoTypeTotalText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberTotalText>
                    {(handler.monthlyPaymentMetrics.renew_metrics && handler.monthlyPaymentMetrics.renew_metrics.recurrings_renew_count) || '-'}
                  </InfoNumberTotalText>
                )}
              </Row>
            </InfoDataGroupBox>
            {/* New Advertisers With Pending Payment */}
            <InfoDataGroupBox 
              margtop={px2vw(20)} 
              width={px2vw(410)}
              title={newClientsPendingPaymentLbl}
            >
              <Row spaceBetween>
                <InfoTypeTotalText>{newClientsPendingPayment}</InfoTypeTotalText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberTotalText>
                    {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.new_advertisers_pending_payment_count) || '-'}
                  </InfoNumberTotalText>
                )}
              </Row>
            </InfoDataGroupBox>
          </Col>
          <Col width="auto">
            {/* Due date Metrics */}
            <InfoDataGroupBox 
              width={px2vw(410)}
              title={totalDueDateNotPaidLbl}
            >
              {/* Total Due date */}
              <Row height="auto" spaceBetween>
                <InfoTypeTotalText>{totalDueDateNotPaid}</InfoTypeTotalText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.3rem',
                      height: '1.3rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberTotalText>
                    {(handler.monthlyPaymentMetrics.total_metrics && handler.monthlyPaymentMetrics.total_metrics.total_due_dates) || '-'}
                  </InfoNumberTotalText>
                )}
              </Row>
              {/* Due date and not blocked */}
              <Row margleft={px2vw(15)} spaceBetween width="95.2%">
                <InfoTypeText>{dueDateNotBlocked}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberText>
                    {(handler.monthlyPaymentMetrics.due_date_metrics && handler.monthlyPaymentMetrics.due_date_metrics.due_date_not_blocked_not_canceled_count) || '-'}
                  </InfoNumberText>
                )}
              </Row>
              {/* Blocked and not canceled */}
              <Row margleft={px2vw(15)} spaceBetween width="95.2%">
                <InfoTypeText>{dueDateBlockedNotCanceled}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberText>
                    {(handler.monthlyPaymentMetrics.due_date_metrics && handler.monthlyPaymentMetrics.due_date_metrics.due_date_blocked_not_canceled_count) || '-'}
                  </InfoNumberText>
                )}
              </Row>
              {/* Canceled */}
              <Row margleft={px2vw(15)} spaceBetween width="95.2%">
                <InfoTypeText>{dueDateCanceled}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberText>
                    {(handler.monthlyPaymentMetrics.due_date_metrics && handler.monthlyPaymentMetrics.due_date_metrics.due_date_canceled_count) || '-'}
                  </InfoNumberText>
                )}
              </Row>
            </InfoDataGroupBox>
            <Row margtop={px2vw(20)}> 
              {/* Due date today */}
              <InfoDataBox
                width={px2vw(195)}
                height={px2vw(150)}
                title={dueTodayLbl}
              >
                <InfoTypeTotalText>{dueToday}</InfoTypeTotalText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.3rem',
                      height: '1.3rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberTotalText>
                    {(handler.monthlyPaymentMetrics.due_date_metrics && handler.monthlyPaymentMetrics.due_date_metrics.due_today_count) || '-'}
                  </InfoNumberTotalText>
                )}
              </InfoDataBox>
              {/* Will Due date */}
              <InfoDataBox 
                width={px2vw(195)} 
                height={px2vw(150)}
                title={beOvercomeLbl}
              >
                <InfoTypeTotalText>{beOvercome}</InfoTypeTotalText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.3rem',
                      height: '1.3rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberTotalText>
                    {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_pending_payment_count) || '-'}
                  </InfoNumberTotalText>
                )}
              </InfoDataBox>
            </Row>
          </Col>
        </Row>
        {/* Renew Payment && New Sales Metrics */}
        <Row margtop={px2vw(20)}>
          {/* Renew Payment Metrics */}
          <InfoDataGroupBox
            title={totalPayingActivePlansLbl}
            width={px2vw(410)}
          >
            {/* Total Payments Renew*/}
            <Row height="auto" spaceBetween>
              <InfoTypeTotalText>{totalPayingActivePlans}</InfoTypeTotalText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.3rem',
                    height: '1.3rem',
                    color: preto,
                    marginTop: `${px2vw(10)}` 
                  }}
                />
              ) : (
                <InfoNumberTotalText>
                  {(handler.monthlyPaymentMetrics.total_metrics && handler.monthlyPaymentMetrics.total_metrics.total_renew_payments) || '-'}
                </InfoNumberTotalText>
              )}
            </Row>
            {/* Starter Payments */}
            <Row margleft={px2vw(15)} spaceBetween width="95.2%">
              <InfoTypeText>{payingStarterPlans}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                    marginTop: `${px2vw(14)}` 
                  }}
                />
              ) : (
                <InfoNumberText>
                  {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_starter_paid_renew_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
            {/* Monthly Payments */}
            <Row margleft={px2vw(15)} spaceBetween width="95.2%">
              <InfoTypeText>{payingMonthlyPlans}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                    marginTop: `${px2vw(14)}` 
                  }}
                />
              ) : (
                <InfoNumberText>
                  {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_month_paid_renew_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
            {/* Trimestral Payments */}
            <Row margleft={px2vw(15)} spaceBetween width="95.2%">
              <InfoTypeText>{payingTrimestralPlans}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                    marginTop: `${px2vw(14)}` 
                  }}
                />
              ) : (
                <InfoNumberText>
                  {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_trimestral_paid_renew_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
            {/* Quarter Payments */}
            <Row margleft={px2vw(15)} spaceBetween width="95.2%">
              <InfoTypeText>{payingQuarterPlans}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                    marginTop: `${px2vw(14)}` 
                  }}
                />
              ) : (
                <InfoNumberText>
                  {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_quarter_paid_renew_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
            {/* Semestral Payments */}
            <Row margleft={px2vw(15)} spaceBetween width="95.2%">
              <InfoTypeText>{payingSemestralPlans}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                    marginTop: `${px2vw(14)}` 
                  }}
                />
              ) : (
                <InfoNumberText>
                  {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_semestral_paid_renew_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
            {/* Annual Payments */}
            <Row margleft={px2vw(15)} spaceBetween width="95.2%">
              <InfoTypeText>{payingAnnualPlans}</InfoTypeText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.1rem',
                    height: '1.1rem',
                    color: preto,
                    marginTop: `${px2vw(14)}` 
                  }}
                />
              ) : (
                <InfoNumberText>
                  {(handler.monthlyPaymentMetrics.payment_metrics && handler.monthlyPaymentMetrics.payment_metrics.total_annual_paid_renew_count) || '-'}
                </InfoNumberText>
              )}
            </Row>
          </InfoDataGroupBox> 
          {/* New Sales Metrics */}
          <InfoDataGroupBox 
            title={totalNewSalesLbl}
            width={px2vw(410)}
          >
            {/* Total New Sales Payments */}
            <Row height="auto" spaceBetween>
              <InfoTypeTotalText>{totalNewSales}</InfoTypeTotalText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.3rem',
                    height: '1.3rem',
                    color: preto,
                    marginTop: `${px2vw(10)}` 
                  }}
                />
              ) : (
                <InfoNumberTotalText>
                  {(handler.monthlyPaymentMetrics.total_metrics && handler.monthlyPaymentMetrics.total_metrics.total_new_sales) || '-'}
                </InfoNumberTotalText>
              )}
            </Row>
            <Row width="auto" spaceBetween>
              {/* New Sales Per Plan */}
              <Col width="auto">
                {/* Starter Payments */}
                <Row margleft={px2vw(5)} spaceBetween>
                  <InfoTypeText>{newSalesStarterPlans}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_starter_new_sales_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Monthly Payments */}
                <Row margleft={px2vw(5)} spaceBetween>
                  <InfoTypeText>{newSalesMonthlyPlans}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_month_new_sales_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Trimestral Payments */}
                <Row margleft={px2vw(5)} spaceBetween>
                  <InfoTypeText>{newSalesTrimestralPlans}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_trimestral_new_sales_count) || '-'}
                    </InfoNumberText>
                  )}      
                </Row>
                {/* Quarter Payments */}
                <Row margleft={px2vw(5)} spaceBetween>
                  <InfoTypeText>{newSalesQuarterPlans}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_quarter_new_sales_count) || '-'}
                    </InfoNumberText>
                  )}      
                </Row>
                {/* Semestral Payments */}
                <Row margleft={px2vw(5)} spaceBetween>
                  <InfoTypeText>{newSalesSemestralPlans}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_semestral_new_sales_count) || '-'}
                    </InfoNumberText>
                  )}      
                </Row>
                {/* Annual Payments */}
                <Row margleft={px2vw(5)} spaceBetween>
                  <InfoTypeText>{newSalesAnnualPlans}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_annual_new_sales_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
              </Col>
              {/* New Sales Per Kuppido Type */}
              <Col width="auto" margleft={px2vw(30)}>
                {/* New Sales Kuppidos 1.0 - External */}
                <Row spaceBetween>
                  <InfoTypeText>{newSalesKuppido10}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_kuppido_10_new_sales_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* New Sales Kuppidos 2.0 */}
                <Row spaceBetween>
                  <InfoTypeText>{newSalesKuppido20}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_kuppido_20_new_sales_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* New Sales Kuppidos 3.0 */}
                <Row spaceBetween>
                  <InfoTypeText>{newSalesKuppido30}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_kuppido_30_new_sales_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* New Sales Kuppidos 4.0 */}
                <Row spaceBetween>
                  <InfoTypeText>{newSalesKuppido40}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.new_sales_metrics && handler.monthlyPaymentMetrics.new_sales_metrics.total_kuppido_40_new_sales_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
              </Col>           
            </Row>
          </InfoDataGroupBox>
        </Row>
        {/* Trial Metrics */}
        <Row margtop={px2vw(20)}>
          <InfoDataGroupBox 
            title={totalTrialRegistersLbl}
            padright={px2vw(40)}
            width={px2vw(840)}
          >
            {/* Total trial registers */}
            <Row height="auto" justifyStart>
              <InfoTypeTotalText>{totalTrialRegisters}</InfoTypeTotalText>
              {handler.getMonthlyPaymentMetricsLoading ? (
                <Spinner
                  style={{
                    width: '1.3rem',
                    height: '1.3rem',
                    color: preto,
                    marginTop: `${px2vw(10)}` 
                  }}
                />
              ) : (
                <InfoNumberTotalText>
                  {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_registers) || '-'}
                </InfoNumberTotalText>
              )}
            </Row>
            {/* Pending trial users & Converted trial users & Not converted trial users */}
            <Row>
              {/* Pending trial users */}
              <Row margleft={px2vw(15)} justifyStart>
                <InfoTypeText>{trialPendingRegisters}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberText>
                    {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_pending_count) || '-'}
                  </InfoNumberText>
                )}
              </Row>         
              {/* Converted trial users */}
              <Row margleft={px2vw(15)} justifyStart>
                <InfoTypeText>{trialConvertedRegisters}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberText>
                    {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_converted_count) || '-'}
                  </InfoNumberText>
                )}
              </Row>
              {/* Not converted trial users */}
              <Row margleft={px2vw(15)} justifyStart>
                <InfoTypeText>{trialNotConvertedRegister}</InfoTypeText>
                {handler.getMonthlyPaymentMetricsLoading ? (
                  <Spinner
                    style={{
                      width: '1.1rem',
                      height: '1.1rem',
                      color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                  />
                ) : (
                  <InfoNumberText>
                    {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_not_converted_count) || '-'}
                  </InfoNumberText>
                )}
              </Row>
            </Row>
            <Row margtop={px2vw(20)} spaceBetween>
              {/* Registers from Kuppidos */}
              <Col>
                {/* Kuppidos 1.0 trial registers */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialRegistersKuppido10}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_10_register_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Kuppidos 2.0 trial registers */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialRegistersKuppido20}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_20_register_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Kuppidos 3.0 trial registers */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialRegistersKuppido30}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_30_register_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Kuppidos 4.0 trial registers */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialRegistersKuppido40}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_40_register_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
              </Col>
              {/* Not converted from Kuppidos */}
              <Col margleft={px2vw(150)}>
                {/* Kuppidos 1.0 trial not converted */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialNotConvertedRegistersKuppido10}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_10_not_converted_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Kuppidos 2.0 trial not converted */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialNotConvertedRegistersKuppido20}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_20_not_converted_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Kuppidos 3.0 trial not converted */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialNotConvertedRegistersKuppido30}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_30_not_converted_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Kuppidos 4.0 trial not converted */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialNotConvertedRegistersKuppido40}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_40_not_converted_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
              </Col>
            </Row>
            <Row margtop={px2vw(20)}>
              {/* Converted from Kuppidos */}
              <Col>
                {/* Kuppidos 1.0 trial converted */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialConvertedRegistersKuppido10}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_10_converted_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Kuppidos 2.0 trial converted */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialConvertedRegistersKuppido20}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_20_converted_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Kuppidos 3.0 trial converted */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialConvertedRegistersKuppido30}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_30_converted_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Kuppidos 4.0 trial converted */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialConvertedRegistersKuppido40}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_40_converted_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
              </Col>
              {/* Pending from Kuppidos */}
              <Col margleft={px2vw(150)}>
                {/* Kuppidos 1.0 trial pending */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialPendingRegistersKuppido10}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_10_pending_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Kuppidos 2.0 trial pending */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialPendingRegistersKuppido20}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_20_pending_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Kuppidos 3.0 trial pending */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialPendingRegistersKuppido30}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_30_pending_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
                {/* Kuppidos 4.0 trial pending */}
                <Row margleft={px2vw(15)} spaceBetween>
                  <InfoTypeText>{trialPendingRegistersKuppido40}</InfoTypeText>
                  {handler.getMonthlyPaymentMetricsLoading ? (
                    <Spinner
                      style={{
                        width: '1.1rem',
                        height: '1.1rem',
                        color: preto,
                        marginTop: `${px2vw(14)}` 
                      }}
                    />
                  ) : (
                    <InfoNumberText>
                      {(handler.monthlyPaymentMetrics.trial_metrics && handler.monthlyPaymentMetrics.trial_metrics.total_kuppido_40_pending_count) || '-'}
                    </InfoNumberText>
                  )}
                </Row>
              </Col>
            </Row>
          </InfoDataGroupBox>
        </Row>
      </Col>
    </Content>
  );

  return metricsContent;
}
