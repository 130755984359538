import styled from 'styled-components';

import { Input } from '@rocketseat/unform';
import px2vw from '../../../utils/responsiveness/px2vw';

import {
  branco,
  verdeKuppi1,
  verdeKuppi2,
  verdeKuppi21,
  cinzaKuppi4,
  cinzaKuppi7,
  cinzaKuppi3,
  vermelhoKuppi1,
  preto,
} from '../../../styles/colors';

export const SearchInput = styled(Input)`
  padding: ${px2vw(2)} !important;
  border: ${px2vw(2)} solid ${cinzaKuppi4} !important;
  border-radius: ${px2vw(5)} ${px2vw(0)} ${px2vw(0)} ${px2vw(5)} !important;
  font-size: ${px2vw(14)} !important;
  font-family: AvenirNextLTPro;
  font-weight: normal !important;
  letter-spacing: 0.36px !important;

  ::placeholder {
    color: ${cinzaKuppi7} !important;
  }

  :focus {
    border: ${px2vw(2)} solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
      width: ${props.width} !important;
      height: ${props.height} !important;
      margin-top: ${props.margtop} !important;
      border: ${props.border} !important;
      border-radius: ${props.borderRadius} !important;
      padding-left: ${props.padleft} !important;
      padding-right: ${props.padright} !important;
   `};

  ${(props) =>
    props.disabled &&
    `
      background: ${cinzaKuppi4} !important;
      color: ${cinzaKuppi3} !important;
      border: 1px solid ${cinzaKuppi3} !important;
    `};

  ${(props) =>
    props.empty &&
    `
      border: 1px solid ${vermelhoKuppi1} !important;
    `};
`;

export const SearchButton = styled.button`
  border: none;
  background-color: ${verdeKuppi2};
  color: ${branco};
  font-weight: bold;
  font-size: ${px2vw(14)};
  width: ${px2vw(150)};
  height: ${px2vw(30)};
  border-radius: ${px2vw(0)} ${px2vw(5)} ${px2vw(5)} ${px2vw(0)};

  ${(props) => props.district && `
    border-radius: ${px2vw(5)};
    width: ${px2vw(100)};
  `}

  ${(props) => props.orders && `
    border-radius: ${px2vw(5)};
  `}
`;

export const InputTitle = styled.div`
  font-size: ${(props) => props.fontsize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-top: ${px2vw(2)};
  display: inline-block;

  ${(props) => ` 
    color: ${props.color};
    margin-top: ${props.margtop};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
    font-size: ${props.fontsize};
  `};

  ${(props) =>
    props.isMobile &&
    `
    font-size: ${px2vw(2)};
  `};
`;

// Advertiser Details
export const GoBackButton = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.48px;
  cursor: pointer;
`;

export const Text = styled.div`
  font-family: AvenirNextLTPro;
  letter-spacing: 0.48px;

  ${(props) => `
    font-size: ${props.fontsize};
    font-weight: ${props.fontweight};
    margin-right: ${props.margright};
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
  `}

  ${(props) => props.name && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25ch;
  `}

  ${(props) => props.slug && `
    text-decoration: underline;
    cursor: pointer;
  `}

  .inactive {
    color: ${vermelhoKuppi1}
  }
`;

export const TabsBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${px2vw(20)};
`;

export const TabBox = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  margin-right: ${px2vw(40)};
  justify-content: center;
`;

export const Tab = styled.button`
  height: ${px2vw(45)};
  width: 100%;
  font-size: ${px2vw(19)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  border: none;
  background-color: transparent;
`;

export const TabSelected = styled.div`
  display: none;
  height: ${px2vw(5)};
  width: ${px2vw(125)};
  background-color: ${verdeKuppi1};
  border-radius: ${px2vw(5)};
  margin-top: ${px2vw(-7)};
  margin-bottom: ${px2vw(20)};

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};

  ${(props) => `
    width: ${props.width};
  `};
`;

export const EditButton = styled.button`
  border: none;
  border-radius: ${px2vw(5)};
  width: ${px2vw(75)};
  height: ${px2vw(30)};
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px ${px2vw(5)};
  background-color: ${cinzaKuppi4};

  ${(props) => props.status && `
    width: ${px2vw(55)};
    height: ${px2vw(20)};
    font-size: ${px2vw(10)};
  `}
`;

export const Hr = styled.div`
  border-top: 2px solid ${cinzaKuppi4};
  height: 1px;
  width: 100%;
`;

export const CancelSubscriptionButton = styled.button`
  border: ${px2vw(2.5)} solid ${preto};
  border-radius: ${px2vw(10)};
  background: ${branco};
  width: ${px2vw(175)};
  height: ${px2vw(30)};
  font-weight: bold;
`;

export const PaymentStatusText = styled.div`
  border-radius: ${px2vw(10)};
  background: ${verdeKuppi21};
  height: ${px2vw(30)};
  width: ${px2vw(150)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${px2vw(10)};
`;

export const WhiteBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${branco};
  padding: ${px2vw(15)};
  border-radius: ${px2vw(10)};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
  
  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
  `}

  ${(props) => props.justifyStart && `justify-content: flex-start;`};
`;

export const ActionButton = styled.button`
  border: ${px2vw(2.5)} solid ${preto};
  border-radius: ${px2vw(10)};
  background: ${branco};
  width: ${px2vw(175)};
  height: ${px2vw(30)};
  font-weight: bold;
`;