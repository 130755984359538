import { takeLatest, call, put, all } from 'redux-saga/effects';

import { apiAdmin } from '../../../services/api';

import {
  getMonthlyPaymentMetricsSuccess,
  getMonthlyPaymentMetricsFailure,
} from './actions';

export function* getMonthlyPaymentMetricsRequest({ payload }) {
  try {
    const { previousMonth } = payload;

    apiAdmin.defaults.headers.post['access-token'] = process.env.REACT_APP_MONTTHLY_REPORTS_ACCESS_TOKEN;

    const response = yield call(
      apiAdmin.post,
      `/monthly-payment-metrics`,
      {
        previous_month: previousMonth,
      }
    );

    const paymentMetrics = response.data.payment_metrics;
    const dueDateMetrics = response.data.due_date_metrics;
    const renewMetrics = response.data.renew_metrics;
    const newSalesMetrics = response.data.new_sales_metrics;
    const totalPayments = {
      total_payments: String(
        parseInt(paymentMetrics.total_starter_paid_renew_count) + 
        parseInt(paymentMetrics.total_month_paid_renew_count) + 
        parseInt(paymentMetrics.total_trimestral_paid_renew_count) +  
        parseInt(paymentMetrics.total_quarter_paid_renew_count) + 
        parseInt(paymentMetrics.total_semestral_paid_renew_count) + 
        parseInt(paymentMetrics.total_annual_paid_renew_count) + 
        parseInt(newSalesMetrics.total_starter_new_sales_count) +
        parseInt(newSalesMetrics.total_month_new_sales_count) + 
        parseInt(newSalesMetrics.total_trimestral_new_sales_count) + 
        parseInt(newSalesMetrics.total_quarter_new_sales_count) + 
        parseInt(newSalesMetrics.total_semestral_new_sales_count) + 
        parseInt(newSalesMetrics.total_annual_new_sales_count)
      ),
      total_renew_payments: String(
        parseInt(paymentMetrics.total_starter_paid_renew_count) + 
        parseInt(paymentMetrics.total_month_paid_renew_count) + 
        parseInt(paymentMetrics.total_trimestral_paid_renew_count) + 
        parseInt(paymentMetrics.total_quarter_paid_renew_count) + 
        parseInt(paymentMetrics.total_semestral_paid_renew_count) + 
        parseInt(paymentMetrics.total_annual_paid_renew_count)
      )
    };
    const totalDueDates = {
      total_due_dates: String(parseInt(dueDateMetrics.due_date_not_blocked_not_canceled_count) + 
        parseInt(dueDateMetrics.due_date_blocked_not_canceled_count) + 
        parseInt(dueDateMetrics.due_date_canceled_count)
      ),
      total_due_dates_possible_paying: String(parseInt(dueDateMetrics.due_date_not_blocked_not_canceled_count) + 
        parseInt(dueDateMetrics.due_date_blocked_not_canceled_count)
      ),
    };
    const totalMetrics = {
      ...totalPayments,
      ...totalDueDates,
      total_possible_paying_advertisers: String(parseInt(totalPayments.total_payments) + 
        parseInt(totalDueDates.total_due_dates_possible_paying) + 
        parseInt(dueDateMetrics.due_today_count) + 
        parseInt(renewMetrics.recurrings_renew_count) +
        parseInt(paymentMetrics.total_pending_payment_count) + 
        parseInt(paymentMetrics.new_advertisers_pending_payment_count)
      ),
      total_new_sales: String(newSalesMetrics.total_new_sales_count)
    };

    yield put(getMonthlyPaymentMetricsSuccess({
      payment_metrics: {
        ...response.data.payment_metrics,
      },
      due_date_metrics: {
        ...response.data.due_date_metrics,
      },
      renew_metrics: {
        ...response.data.renew_metrics,
      },
      new_sales_metrics: {
        ...response.data.new_sales_metrics
      },
      total_metrics: {
        ...totalMetrics
      },
      trial_metrics: {
        ...response.data.trial_metrics
      }
    }));
  } catch (err) {
    yield put(getMonthlyPaymentMetricsFailure());
  }
}

export default all([
  takeLatest(
    '@monthlyPaymentMetrics/GET_MONTHLY_PAYMENT_METRICS_REQUEST',
    getMonthlyPaymentMetricsRequest
  )
]);
