import styled from 'styled-components';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Row } from '../../styles/grid';
import px2vw from '../../utils/responsiveness/px2vw';
import {
  branco,
  preto2,
  cinzaKuppi1,
  cinzaKuppi3,
  cinzaKuppi7,
  cinzaKuppi4,
} from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${px2vw(15)};

  @media screen and (max-width: 1000px) {
    overflow-x: auto;
  }
`;

// export const Header = styled.div`
export const Header = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${px2vw(20)} ${px2vw(4)};

  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-style: normal;

  ${(props) =>
    props.dataClients &&
    `
    justify-content: start;
  `};
  ${(props) =>
    props.district &&
    `
    justify-content: start;
    align-items: start;
  `}
`;

export const HeaderButton = styled.button`
  text-align: left;

  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-style: normal;
  font-size: ${px2vw(12)};
  background: transparent !important;
  border: none;
  box-shadow: none;

  /* Put ellipsis when the is no space */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${px2vw(20)} ${px2vw(4)};

  width: ${(props) => props.width || '100%'};

  font-family: AvenirNextLTPro;
  font-weight: normal;
  font-style: normal;
  font-size: ${px2vw(12)};

  ${(props) =>
    props.district &&
    `
    justify-content: start;
    align-items: start;

    span {
      margin-left: ${px2vw(-230)};
      max-width: 100ch;
    }
  `}

  ${(props) => props.slug && `
    text-decoration: underline;
    cursor: pointer;
  `}

  span {
    max-width: ${(props) => props.maxCharLength || '20ch'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TableRow = styled(Row)`
  :hover {
    background: ${cinzaKuppi1};
  }

  ${(props) =>
    props.dataClients &&
    `
    :hover {
      background: ${cinzaKuppi1};

      button {
        background: ${cinzaKuppi4};
      }
    }
  `}
`;

export const Hr = styled.div`
  border-top: 2px solid ${cinzaKuppi1};
  height: 1px;
  width: 100%;
`;

export const PagButton = styled.button`
  width: ${px2vw(40)};
  height: ${px2vw(40)};
  border-radius: ${px2vw(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  :hover {
    ${(props) =>
      props.disabled
        ? `background: transparent !important; cursor: not-allowed !important`
        : `background: ${cinzaKuppi1} !important`};
  }
`;

export const PrevIcon = styled(AiOutlineLeft).attrs({
  size: px2vw(12),
})`
  color: ${(props) => props.disabled && cinzaKuppi7};
`;
export const NextIcon = styled(AiOutlineRight).attrs({
  size: px2vw(12),
})`
  color: ${(props) => props.disabled && cinzaKuppi7};
`;

// For DataClients Table
export const EditDetailsButton = styled.button`
  width: ${px2vw(125)};
  height: ${px2vw(30)};
  border: none;
  border-radius: ${px2vw(5)};
  background: ${cinzaKuppi4};
`;

// For Orders
export const CheckButton = styled.div`
  border-radius: ${px2vw(4)};
  border: ${px2vw(2)} solid ${cinzaKuppi3};
  background-color: ${branco};
  width: ${px2vw(16)};
  height: ${px2vw(16)};
  cursor: pointer;

  ${(props) => `
    margin-right: ${props.margright};
  `}
`;

export const ImageContainer = styled.div`
  width: ${px2vw(14)};
  height: ${px2vw(14)};
  margin-top: ${px2vw(-3)};
  display: none;
  border-radius: ${px2vw(4)};

  ${(props) =>
    props.isChecked &&
    `
        display: block;
      `};
`;

export const DeleteOrderButton = styled.button`
  width: ${px2vw(90)};
  height: ${px2vw(30)};
  padding: 0 ${px2vw(10)};
  background-color: ${branco};
  border: ${px2vw(2)} solid ${preto2};
  border-radius: ${px2vw(9)};
  color: ${preto2};
  font-weight: 700;
  display: flex;
  justify-content: space-around;
  align-items: center;

  img {
    width: ${px2vw(15)};
    height: ${px2vw(15)};
  }
`
