import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { parse } from 'date-fns';


import history from '../../../services/history';
import { dataClients } from '../../../routes/routeMap';

import { apiAdmin, apiGlobal } from '../../../services/api';

import { logout } from '../../../store/modules/auth/actions';
import {
  getClientsSuccess,
  getClientsFailure,
  getClientDataSuccess,
  getClientDataFailure,
  formatSubscriptions as formatSubscriptionsAction,
  getSubscriptionsSuccess,
  getSubscriptionsFailure,
  getRecurringSuccess,
  getClientCategoriesSuccess,
  getClientCategoriesFailure,
  getSubscriptionsListSuccess,
  getSubscriptionsListFailure,
  getLocalitiesSuccess,
  getLocalitiesFailure,
  getDistrictsSuccess,
  getDistrictsFailure,
  updateRepresentativeDataSuccess,
  updateRepresentativeDataFailure,
  updateStoreDataSuccess,
  updateStoreDataFailure,
  updateStoreStatusDataSuccess,
  updateStoreStatusDataFailure,
  updateStoreSubscriptionDataSuccess,
  updateStoreSubscriptionDataFailure,
  manualConciliationSuccess,
  manualConciliationFailure,
  addDistrictSuccess,
  addDistrictFailure,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  getOrdersSuccess,
  getOrdersFailure,
  deleteOrdersSuccess,
  deleteOrdersFailure,
  generateNewPasswordSuccess,
  generateNewPasswordFailure,
  removeCreditCardSuccess,
  removeCreditCardFailure,
  getKuppiBoostDataSuccess,
  getKuppiBoostDataFailure,
  addKuppiBoostBalanceSuccess,
  addKuppiBoostBalanceFailure,
  getBoostCampaignsSuccess,
  getBoostCampaignsFailure,
  paymentReversalSuccess,
  paymentReversalFailure,
  editFacebookInstagramIdSuccess,
  editFacebookInstagramIdFailure,
  getNearClientsSuccess,
  getNearClientsFailure,
  getPixelOwnDomainDataSuccess,
  getPixelOwnDomainDataFailure,
  updatePixelsListSuccess,
  updatePixelsListFailure,
  getWhatsappDataSuccess,
  getWhatsappDataFailure,
  forceWhatsappDisconnectionSuccess,
  forceWhatsappDisconnectionFailure,
  getMassMessageClientDataSuccess,
  getMassMessageClientDataFailure,
  addMassMessageClientBalanceSuccess,
  addMassMessageClientBalanceFailure,
  getMassMessageClientCampaignsSuccess,
  getMassMessageClientCampaignsFailure,
  editMassMessageClientSuccess,
  editMassMessageClientFailure
} from './actions';

/** Client Data **/
export function* getClientsRequest({ payload }) {
  try {
    const response = yield call(apiAdmin.get, `/clients`, {
      params: { criteria: payload.criteria },
    });

    if (response.data.length === 0) {
      toast.error('Nenhum cliente encontrado. Por favor, tente novamente!');
    }

    yield put(getClientsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getClientsFailure());
      toast.error('Erro ao buscar cliente. Por favor, tente novamente!', {
        autoClose: 6000,
      });
    }   
  }
}

export function* getClientDataRequest({ payload }) {
  try {
    const response = yield call(apiAdmin.get, `/clients/${payload.advertiserInfoId}`);
    yield put(getClientDataSuccess(response.data));
    if (payload.screen === 'MassMessageUnlimitedPackage') {
      history.push(dataClients)
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getClientDataFailure());
      toast.error('Erro ao carregar dados do cliente. Por favor, tente novamente!', {
        autoClose: 6000,
      });
    } 
  }
}

export function* getClientCategoriesRequest() {
  try {
    //Get Client categories
    const categories = yield call(apiGlobal.get, `/anunciante/categories`);

    // Store Client categories inside redux
    yield put(getClientCategoriesSuccess(categories.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getClientCategoriesFailure());
    }    
  }
}

export function* updateRepresentativeDataRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/representative-data`,
      {
        email: payload.email,
        representative_name: payload.representativeName,
        phone_whatsapp_representative: payload.phoneWhatsappRepresentative,
      }
    );
    yield put(updateRepresentativeDataSuccess());
    toast.success('Dados salvos com sucesso!');
    if (response.status === 200) {
      yield put(getClientDataSuccess(response.data));
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 6000,
        });
      }

      yield put(updateRepresentativeDataFailure());
    }
  }
}

export function* generateNewPasswordRequest({ payload }) {
  try {
    yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/generate-new-password`
    );
    yield put(generateNewPasswordSuccess());
    toast.success('Nova senha gerada com sucesso. Email enviado ao cliente.');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(generateNewPasswordFailure());
      toast.error('Erro ao gerar nova senha, por gentileza tentar novamente!', {
        autoClose: 6000,
      });
    }
  }
}

export function* updateStoreDataRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/store-data`,
      {
        name: payload.name,
        cpf_cnpj: payload.cpfCnpj,
        category: payload.category,
        latitude: payload.latitude,
        longitude: payload.longitude,
        is_visible_on_web_menu: payload.isVisibleOnWebMenu,
      }
    );
    yield put(updateStoreDataSuccess());
    toast.success('Dados salvos com sucesso!');
    if (response.status === 200) {
      yield put(getClientDataSuccess(response.data));
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 6000,
        });
      } else {
        toast.error('Ocorreu um erro ao salvar os dados. Tente novamente!', {
          autoClose: 6000,
        });
      }
    
      yield put(updateStoreDataFailure());
    }
  }
}

export function* updateStoreStatusDataRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/store-data/status`,
      {
        no_overdue_block_until_at: payload.noOverdueBlockUntilAt,
      }
    );
    yield put(updateStoreStatusDataSuccess());
    toast.success('Status salvo com sucesso!');
    if (response.status === 200) {
      yield put(getClientDataSuccess(response.data));
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 6000,
        });
      } else {
        toast.error('Ocorreu um erro ao salvar o status. Tente novamente!', {
          autoClose: 6000,
        });
      }
      yield put(updateStoreStatusDataFailure());
    }
  }
}

/** Near Clients **/
export function* getNearClientsRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.post,
      `/clients/near-clients`,
      {
        state: payload.state,
        city: payload.city,
        latitude: payload.latitude,
        longitude: payload.longitude,
        radius: payload.radius,
      }
    );
    yield put(getNearClientsSuccess(response.data));
  } catch (err) {
    if (err?.response?.data?.error?.message) {
      toast.error(err.response.data.error.message, {
        autoClose: 6000,
      });
    } else {
      toast.error('Ocorreu um erro ao buscar clientes. Tente novamente!', {
        autoClose: 6000,
      });
    }
    yield put(getNearClientsFailure());
  }
}

/** Subscriptions **/
export function* getSubscriptionsListRequest() {
  try {
    //Get subscriptions list
    const subscriptionsList = yield call(apiGlobal.get, `/subscriptions?list_all=true`);

    // Store subscriptions list inside redux
    yield put(getSubscriptionsListSuccess(subscriptionsList.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getSubscriptionsListFailure());
    }
  }
}

export function* formatSubscriptions({ payload }) {
  function* getPayable() {
    yield* payload.subscriptions;
  }

  const sequence = getPayable();
  let hasIterable = true;
  let isDuePayment = false;
  let hasFreeTrialValid = true;

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      // Verify if the user has a delayed payment
      if (
        (value.status === 'not_paid' || value.status === 'waiting_payment') &&
        !value.paid_at &&
        parse(value.start_validity_at, 'dd/MM/yyyy', new Date()) - new Date() <
          0
      ) {
        isDuePayment = true;
        hasIterable = false;
      } else if (value.status === 'paid' && value.payment_type !== 'free') {
        hasFreeTrialValid = false;
      }
    } else {
      hasIterable = false;
    }
  }

  yield put(
    getSubscriptionsSuccess(
      payload.subscriptions,
      isDuePayment,
      hasFreeTrialValid
    )
  );
}

export function* getSubscriptionsRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.get,
      `/clients/${payload.advertiserInfoId}/subscriptions`
    );

    yield put(formatSubscriptionsAction(response.data.subscriptions));
    yield put(getRecurringSuccess(response.data.recurring));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao buscar dados de assinatura. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(getSubscriptionsFailure());
    }
  }
}

export function* updateStoreSubscriptionDataRequest({ payload }) {
  try {
    yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/subscriptions`,
      {
        subscription_id: payload.subscriptionId,
        amount: payload.amount,
        subscription_update_period: payload.signatureUpdatePeriod,
      }
    );
    yield put(updateStoreSubscriptionDataSuccess());
    toast.success('Dados de assinatura salvos com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 6000,
        });
      }
      yield put(updateStoreSubscriptionDataFailure());
    }
  }
}

export function* manualConciliationRequest({ payload }) {
  try {
    yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/subscriptions/manual-conciliation`,
      {
        subscription_id: payload.subscriptionId,
        processor_transaction_id: payload.transactionId,
        payment_type: payload.paymentType,
      }
    );
    yield put(manualConciliationSuccess());
    toast.success('Conciliação manual realizada com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 6000,
        });
      }
      yield put(manualConciliationFailure());
    }
  }
}

export function* cancelSubscriptionRequest({ payload }) {
  try {
    yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/subscriptions/cancel`
    );
    yield put(cancelSubscriptionSuccess());
    toast.success('Dados de assinatura salvos com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao cancelar assinatura, por gentileza tentar novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(cancelSubscriptionFailure());
    }
  }
}

export function* removeCreditCardRequest({ payload }) {
  try {
    yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/subscriptions/remove-credit-card`
    );
    yield put(removeCreditCardSuccess());
    toast.success('Cartão de crédito removido com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao remover cartão de crédito, por gentileza tentar novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(removeCreditCardFailure());
    }
  }
}

/** Kuppi Boost **/
export function* getKuppiBoostDataRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.get,
      `/clients/${payload.advertiserInfoId}/kuppi-boost`
    );
    yield put(getKuppiBoostDataSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao carregar dados do Kuppi Boost do cliente. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(getKuppiBoostDataFailure());
    }
  }
}

export function* getBoostCampaignsRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.get,
      `/clients/${payload.advertiserInfoId}/kuppi-boost/campaigns`
    );
    yield put(getBoostCampaignsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao carregar dados do Kuppi Boost do cliente. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(getBoostCampaignsFailure());
    }
  }
}

export function* addKuppiBoostBalanceRequest({ payload }) {
  try {
    yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/kuppi-boost/balance`,
      {
        balance: payload.balance,
        mgm_id: payload.mgmId,
      }
    );
    yield put(addKuppiBoostBalanceSuccess());
    toast.success('Saldo do Boost adicionado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro ao adicionar saldo para o cliente. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(addKuppiBoostBalanceFailure());
    }
  }
}

export function* paymentReversalRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/kuppi-boost/payment/reversal`,
      {
        campaign_id: payload.campaignId,
      }
    );
    if (response.data?.message) {
      toast.warning(response.data.message);
      yield put(paymentReversalSuccess());
    } else {
      yield put(paymentReversalSuccess());
      toast.success('Saldo da campanha estornado com sucesso!');
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro ao estornar saldo da campanha. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(paymentReversalFailure());
    }
  }
}

export function* editFacebookInstagramIdRequest({ payload }) {
  try {
    yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/kuppi-boost/facebook-instagram-id`,
      {
        facebook_id: payload.facebookId,
        instagram_id: payload.instagramId,
      }
    );

    yield put(editFacebookInstagramIdSuccess());
    toast.success('ID do Facebook/ Instagram alterado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro editar os ids do Kuppi Boost do cliente. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(editFacebookInstagramIdFailure());
    }
  }
}

/** Neighborhood **/
export function* getLocalitiesRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.get,
      `/clients/dne/localities?page=1&limit=1700&uf=${payload.selectedState}`
    );
    yield put(getLocalitiesSuccess(response.data.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Erro ao carregar os municípios do estado selecionado. Tente novamente!', {
        autoClose: 6000,
      });
  
      yield put(getLocalitiesFailure());
    }
  }
}

export function* getDistrictsRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.get,
      `/clients/dne/districts?page=1&limit=2200&locality_id=${payload.localityId}`
    );
    yield put(getDistrictsSuccess(response.data.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao carregar os bairros do município selecionado. Tente novamente!',
        {
          autoClose: 6000,
        }
      );
  
      yield put(getDistrictsFailure());
    }
  }
}

export function* addDistrictRequest({ payload }) {
  try {
    yield call(apiAdmin.post, `/clients/dne/districts/insert`, {
      dne_locality_id: payload.dneLocalityId,
      neighborhood: payload.neighborhood,
    });
    yield put(addDistrictSuccess());
    toast.success('Bairro adicionado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Erro ao adicionar o bairro. Tente novamente!', {
        autoClose: 6000,
      });
      yield put(addDistrictFailure());
    }
  }
}

/** Orders **/
export function* getOrdersRequest({ payload }) {
  try {
    const response = yield call(apiAdmin.post, `/clients/orders`, {
      ids: payload.ordersIds,
    });
    yield put(getOrdersSuccess(response.data));

    if (response.data.length === 0) {
      toast.error(
        'Pedido(s) não encontrado(s). Tente novamente.',
        {
          autoClose: 6000,
        }
      );
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao buscar o(s) pedido(s) selecionado(s). Tente novamente.',
        {
          autoClose: 6000,
        }
      );
      yield put(getOrdersFailure());
    }
  }
}

export function* deleteOrdersRequest({ payload }) {
  try {
    yield call(apiAdmin.put, `/clients/orders/delete`, {
      ids: payload.ordersIds,
    });
    yield put(deleteOrdersSuccess());
    toast.success('Pedido(s) deletado(s) com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao deletar pedido(s). Tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(deleteOrdersFailure());
    }
  }
}

/** Pixel / OwnDomain **/
export function* getPixelOwnDomainDataRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.get,
      `/clients/${payload.advertiserInfoId}/pixel-own-domain`
    );
    yield put(getPixelOwnDomainDataSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao carregar dados de pixel e domínio próprio do cliente. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(getPixelOwnDomainDataFailure());
    }
  }
}

export function* updatePixelsListRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/pixel-own-domain/pixel`,
      {
        pixels_list: payload.pixelsList.length === 0 ? null : payload.pixelsList,
      }
    );
    yield put(updatePixelsListSuccess(response.data));
    toast.success('Lista de Pixels atualizada!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro ao atualizar a lista de pixels do cliente. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(updatePixelsListFailure());
    }
  }
}

/** Whatsapp **/
export function* getWhatsappDataRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.get,
      `/clients/${payload.advertiserInfoId}/whatsapp`
    );
    yield put(getWhatsappDataSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao carregar dados de whatsapp do cliente. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(getWhatsappDataFailure());
    }
  }
}

export function* forceWhatsappDisconnectionRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.get,
      `/clients/${payload.advertiserInfoId}/whatsapp/force-disconnection`,
    );
    yield put(forceWhatsappDisconnectionSuccess(response.data));
    toast.success('Desconexão feita com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro ao forçar a desconexão do whatsapp do cliente. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(forceWhatsappDisconnectionFailure());
    }
  }
}

/** Mass Message **/
export function* getMassMessageClientDataRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.get,
      `/clients/${payload.advertiserInfoId}/mass-message`
    );
    yield put(getMassMessageClientDataSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao carregar dados do Disparo em Massa do cliente. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(getMassMessageClientDataFailure());
    }
  }
}

export function* getMassMessageClientCampaignsRequest({ payload }) {
  try {
    const response = yield call(
      apiAdmin.get,
      `/clients/${payload.advertiserInfoId}/mass-message/campaigns`
    );
    yield put(getMassMessageClientCampaignsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao carregar dados do Disparo em Massa do cliente. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(getMassMessageClientCampaignsFailure());
    }
  }
}

export function* addMassMessageClientBalanceRequest({ payload }) {
  try {
    yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/mass-message/balance`,
      {
        balance: payload.balance,
        balance_type: payload.balanceType,
      }
    );
    yield put(addMassMessageClientBalanceSuccess());
    toast.success('Saldo do Disparo em Massa adicionado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro ao adicionar saldo para o cliente. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(addMassMessageClientBalanceFailure());
    }
  }
}

export function* editMassMessageClientRequest({ payload }) {
  try {
    yield call(
      apiAdmin.put,
      `/clients/${payload.advertiserInfoId}/mass-message`,
      {
        is_using_package: payload.isUsingPackage,
        per_contact_amount: payload.newAmount,
      }
    );
    yield put(editMassMessageClientSuccess());

    if (payload.isUsingPackage) {
      toast.success('Pacote de disparo ilimitado ativado com sucesso!');
    } else {
      toast.success('Valor do disparo por contato atualizado com sucesso e pacote ilimitado não ativado!');
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro ao atualizar o disparo em massa do cliente. Por favor, tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(editMassMessageClientFailure());
    }
  }
}

export default all([
  takeLatest('@clients/GET_CLIENTS_REQUEST', getClientsRequest),
  takeLatest('@clients/GET_CLIENT_DATA_REQUEST', getClientDataRequest),
  takeLatest(
    '@clients/GET_CLIENT_CATEGORIES_REQUEST',
    getClientCategoriesRequest
  ),
  takeLatest(
    '@clients/UPDATE_REPRESENTATIVE_DATA_REQUEST',
    updateRepresentativeDataRequest
  ),
  takeLatest(
    '@clients/GENERATE_NEW_PASSWORD_REQUEST',
    generateNewPasswordRequest
  ),
  takeLatest('@clients/UPDATE_STORE_DATA_REQUEST', updateStoreDataRequest),
  takeLatest(
    '@clients/UPDATE_STORE_STATUS_DATA_REQUEST',
    updateStoreStatusDataRequest
  ),
  takeLatest('@clients/GET_SUBSCRIPTIONS_REQUEST', getSubscriptionsRequest),
  takeLatest('@clients/FORMAT_SUBSCRIPTIONS', formatSubscriptions),
  takeLatest('@clients/GET_NEAR_CLIENTS_REQUEST', getNearClientsRequest),
  takeLatest(
    '@clients/GET_SUBSCRIPTIONS_LIST_REQUEST',
    getSubscriptionsListRequest
  ),
  takeLatest(
    '@clients/UPDATE_STORE_SUBSCRIPTION_DATA_REQUEST',
    updateStoreSubscriptionDataRequest
  ),
  takeLatest('@clients/MANUAL_CONCILIATION_REQUEST', manualConciliationRequest),
  takeLatest('@clients/CANCEL_SUBSCRIPTION_REQUEST', cancelSubscriptionRequest),
  takeLatest('@clients/REMOVE_CREDIT_CARD_REQUEST', removeCreditCardRequest),
  takeLatest('@clients/GET_KUPPI_BOOST_DATA_REQUEST', getKuppiBoostDataRequest),
  takeLatest('@clients/GET_BOOST_CAMPAIGNS_REQUEST', getBoostCampaignsRequest),
  takeLatest(
    '@clients/ADD_KUPPI_BOOST_BALANCE_REQUEST',
    addKuppiBoostBalanceRequest
  ),
  takeLatest('@clients/PAYMENT_REVERSAL_REQUEST', paymentReversalRequest),
  takeLatest(
    '@clients/EDIT_FACEBOOK_INSTAGRAM_ID_REQUEST',
    editFacebookInstagramIdRequest
  ),
  takeLatest('@clients/GET_LOCALITIES_REQUEST', getLocalitiesRequest),
  takeLatest('@clients/GET_DISTRICTS_REQUEST', getDistrictsRequest),
  takeLatest('@clients/ADD_DISTRICT_REQUEST', addDistrictRequest),
  takeLatest('@clients/GET_ORDERS_REQUEST', getOrdersRequest),
  takeLatest('@clients/DELETE_ORDERS_REQUEST', deleteOrdersRequest),
  takeLatest('@clients/GET_PIXEL_OWN_DOMAIN_DATA_REQUEST', getPixelOwnDomainDataRequest),
  takeLatest('@clients/UPDATE_PIXELS_LIST_REQUEST', updatePixelsListRequest),
  takeLatest('@clients/GET_WHATSAPP_DATA_REQUEST', getWhatsappDataRequest),
  takeLatest('@clients/FORCE_WHATSAPP_DISCONNECTION_REQUEST', forceWhatsappDisconnectionRequest),
  takeLatest('@clients/GET_MASS_MESSAGE_CLIENT_DATA_REQUEST', getMassMessageClientDataRequest),
  takeLatest('@clients/GET_MASS_MESSAGE_CLIENT_CAMPAIGNS_REQUEST', getMassMessageClientCampaignsRequest),
  takeLatest(
    '@clients/ADD_MASS_MESSAGE_CLIENT_BALANCE_REQUEST',
    addMassMessageClientBalanceRequest
  ),
  takeLatest(
    '@clients/EDIT_MASS_MESSAGE_CLIENT_REQUEST',
    editMassMessageClientRequest
  ),
]);
