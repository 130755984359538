import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../Table';

import px2vw from '../../../utils/responsiveness/px2vw';
import { cpfCnpjMask } from '../../../utils/masks';

const columns = [
  {
    field: 'advertiser_info_id',
    title: 'ID',
    width: px2vw(50),
    render: ({ id }) => id,
  },
  {
    field: 'cpf_cnpj',
    title: 'CPF/CNPJ',
    width: px2vw(150),
    render: ({ cpf_cnpj }) => cpfCnpjMask(cpf_cnpj),
  },
  {
    field: 'name',
    title: 'Nome',
    width: px2vw(250),
    render: ({ name }) => name,
  },
  {
    field: 'email',
    title: 'Email',
    width: px2vw(200),
    render: ({ email }) => email,
  },
  {
    field: 'slug',
    title: 'Slug',
    width: px2vw(200),
    render: ({ slug }) => slug,
  },
];

function DataClientsTable({ data, handler }) {
  return (
    <Table
      data={data}
      columns={columns}
      clientsType="DataClients"
      handler={handler}
    />
  );
}

DataClientsTable.propTypes = {
  data: PropTypes.array,
};

export default DataClientsTable;
